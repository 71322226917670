body, html {
  margin: 0;
  /* font-family: 'Abel'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: white;
  background: #9dabba;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #68859a, #9dabba);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #68859a, #9dabba); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

h1, h2, p {
  text-align: center;
}


footer {
  /* background-color: #333333; */
  text-align: center;
  position: inherit;
  width: 100%;
  line-height: 40px;
  font-size: 0.7em;
  text-align: center;
  bottom:0;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  border: 10px solid #f2f2f2;
  /* #001729; */
}

.logo1 {
  max-height: 100%;
  max-width: 100%;
  width: 50px;
  height: 50px;
  border: 0px solid;
  background-size: cover;
  float: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
  width: 50%;
  background-color: rgb(23, 104, 43);
  overflow: auto;
  height: auto;
}
.links {
  display: inline-block;
  text-align: center;
  padding: 14px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 17px;
}
.links:hover {
  background-color: rgb(129, 123, 123);
}
.selected{
  background-color: rgb(0, 56, 42);
}
.image-nav{
  min-height: 400px;
  padding: 50px 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.circular--square {
  border-radius: 50%;
  max-width: 400px;
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

hr {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.topnav {
  overflow: hidden;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 5px;
  text-decoration: none;
  font-size: 17px;
  width: 16%;
}

.topnav a:hover {
  color: #FFD700;
}

.topnav a.active {
  color: white;
}

.netbox .icon {
  padding: 0.6rem 0.6rem;
  color: #444;
  transition: color 140ms ease;
  margin: 0 0.4rem;
}

.link {
  text-decoration: none;
}

a:hover {
  color: #FFD700;
}

.pats {
  width: 30%;
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .topnav a {
    margin: 0 50px 0 20px;
  }
}

@media screen and (max-width: 830px) {
  .pats {
    width: 100%;
  }
}